<template>

	<div class="main-contents">
		<div class="mem_container">
			<div class="mem_info_contents">
				
				<div class="mem_contents_header">
					<div class="title-txt">내프로젝트 &gt; 관심/지원 프로젝트<br/>
						<p><span class="prj_ct">{{pageInfo.totalRecordCount}}</span>개의 프로젝트가 있습니다.</p>
					</div>
				</div>

				<div class="filter">
					<div class="search_box">
						<SelectComp type="select" class="prj_name" list="1:프로젝트명,2:수행사명" v-model="input.searchGubun"/>						
						<InputComp type="text" classNm="search_box int" :placeholder="input.searchGubun == '1' ? '프로젝트명' : '수행사명'" v-model="input.searchKeyword" @keyup.enter="getList('1')"  />
						<div class="search_btn" @click="getList('1')"><img class="search" src="/images/search_btn.png" alt="검색버튼"/></div>
					</div>
					<div class="filter_box prj">
						<ul>
							<li><p class="search_filter">검색 필터</p></li>
							<li><img :class="input.orderGubun == '1' ? 'filter' : ''" src="/images/icon_filter.png" alt="필터"/><p @click="orderSearch('1')">최신 등록 순</p><span>ㅣ</span></li>
							<li><img :class="input.orderGubun == '2' ? 'filter' : ''" src="/images/icon_filter.png" alt="필터"/><p @click="orderSearch('2')">지원 적은 순</p><span>ㅣ</span></li>
							<li><img :class="input.orderGubun == '3' ? 'filter' : ''" src="/images/icon_filter.png" alt="필터"/><p @click="orderSearch('3')">지원 많은 순</p><span>ㅣ</span></li>
							<li><img :class="input.orderGubun == '4' ? 'filter' : ''" src="/images/icon_filter.png" alt="필터"/><p @click="orderSearch('4')">마감 임박 순</p></li>
						</ul>
					</div>
				</div>

				<div class="itv-box">
					<!-- 검색 필터 부분 -->
					<div class="check_ft">
						<SearchFilter name="프로젝트 구분" v-model="input.projectDivCd" list="2:제안단계,1:수행예정" @change="getList('1', false)"/>
						<SearchFilter name="직무" cdId="PRO141" v-model="input.PRO141" @change="getList('1', false)" />
						<SearchFilter name="경력" cdId="PRO107" v-model="input.PRO107" @change="getList('1', false)"/>
						<SearchFilter name="기술등급" cdId="PRO106" v-model="input.PRO106" @change="getList('1', false)"/>
					</div>

					<!-- 모집중 & new -->
					<div v-for="interestApp in interestAppList" :key="interestApp.projectSeq" class="view int_prj box-hover">
						<div class="req">
							<div class="top">
								<div v-if="interestApp.projectDiv == '2'" class="proc bp">제안단계</div>
                                <!-- <div v-else class="proc ep">수행예정</div> -->

								<div v-if="interestApp.recrEndDateDiff >= 0"  class="proc">+모집중</div>
								<div v-if="interestApp.recrEndDateDiff < 0" class="proc cp">모집마감</div>
								<div v-if="interestApp.newYn == 'Y'" class="new">new</div>

								
								<div class="right flex">
									<div v-if="interestApp.interviewYn == 'Y'">
										<img class="itv_sm" src="/images/tec/prj/interview_sm.png" alt="인터뷰아이콘"/>
										<span class="top">프로젝트 인터뷰 요청</span>
									</div>
									<div v-if="interestApp.appYn == 'Y'" class="status">지원완료</div>
									<img v-if="interestApp.interestYn == 'Y'" src="/images/tec/prj/heart_01.png" alt="관심등록"/>
									<img v-else src="/images/tec/prj/heart_00.png" alt="관심등록"/>
									<div class="date">등록일자 {{interestApp.regYyyymmdd}}</div>
								</div>
							</div>
							<div class="itv-outline int_prj_new">
								<div class="regi" style="width: 770px;">
									<div  v-if="interestApp.projectDisplayDiv == '1'"  class="tit int_prj">프로젝트명</div>
									<div v-else class="tit int_prj flog">공급기업 지정</div>
									<div class="name prj-tit-hover" @click="clickProjectInfo(interestApp.projectSeq)">{{interestApp.projectNm}}</div>
									<div class="int_box">
										<div class="list-view int_prj">
											<div class="label ivl03">개요</div>
											<div class="list text">{{interestApp.projectDtlDesc}}</div>
										</div>
										<div class="list-view int_prj">
											<div class="label ivl04">고객사</div>
											<div class="list">
												<div class="add">{{interestApp.clientNm}}</div>
												<div class="loca"><img src="/images/location.png" alt="위치" />{{interestApp.projectLocCont}}</div>
											</div>											
										</div>
										<div class="list-view int_prj">
											<div class="label ivl05">산업분야</div>
											<div class="list" style="display: inline-block !important;"><SelectComp type="text" cdId="PRO105" :value="interestApp.indtypeFilterCd"/> / <SelectComp type="text" cdId="PRO143" :value="interestApp.indtypeClassCd"/></div>
                                        </div>
									</div>
								</div>

								<div class="int_box">                                            
									<div class="list-view int_prj">
										<div class="label ivl04">수행사</div>
										<div v-if="interestApp.projectDisplayDiv == '3'" class="list">{{interestApp.parentCorpNm}}</div>
										<div v-else class="list">{{interestApp.corpNm}}</div>
									</div>
									<div v-if="interestApp.projectDisplayDiv == '3'" class="list-view int_prj">
										<div class="label ivl04">공급사</div>
										<div class="list text">{{interestApp.corpNm}}</div>
									</div>
									<div class="list-view int_prj">
										<div class="label ivl05">개발기간</div>
										<div class="list">{{interestApp.projectStartEndYyyymmdd}}</div>
									</div>
									<div class="list-view int_prj">
										<div class="label ivl05">직무분야</div>
										<div class="list">
											<ul class="duty-list">
												<li v-for="(duty, index) in interestApp.dutyList" :key="index">
													<span>
														<SelectComp type="text_comma_split" cdId="PRO141" :value="duty.dutyFilterCd"/>/<SelectComp type="text_comma_split" cdId="PRO142" :value="duty.dutyFieldCd"/>
													</span>
												</li>
											</ul>
										</div>
									</div>
									<div class="list-view int_prj">
										<div class="label ivl05">개발기술</div>
										<div class="list text">{{interestApp.devTechCont}}</div>
									</div>                                            
								</div>
							</div>

							<!--고객CI/지원자정보-->
							<div class="client_info">
								<div class="ci_box">
									<ImageComp type="image" :src="'/api/prj/tec/projectCorpImg?projectSeq='+interestApp.projectSeq" defaultSrc="COMPANY" alt="고객사 로고"/>
								</div>
								<div class="deadline">
									<span v-if="interestApp.recrEndDateDiff >= 0">
										<img class="period" src="/images/tec/prj/date.png" alt="마감기간"/> 마감 <span class="period">{{interestApp.recrEndDateDiff}}일</span> 전
									</span>
									<img class="member" src="/images/tec/prj/member_sm.png" alt="지원자수"/> <span class="ivl06">지원자 </span><span class="member">{{interestApp.appCount}}명</span>
								</div>
							</div>

						</div>
					</div>	
					
					<div v-if="pageInfo.totalRecordCount == 0" class="view int_prj">
						<div class="req none">
							관련 내용이 존재하지 않습니다!
						</div>
					</div>
				</div>
				<!--페이징 컴포넌트 -->
				<PagingComp :pageInfo="pageInfo" @page-click="goPage"/>
				<!--// 페이징 컴포넌트 -->
			</div>
		</div>
	</div>

</template>

<script>

import PagingComp from '@/components/PagingComp.vue';
import SearchFilter from '@/components/base/SearchFilter.vue';

export default {

	components : {
		PagingComp ,
		SearchFilter
	},

	data() {
		return {
			input: {
				pageIndex : '1',
				orderGubun : '1',	// 1:최신등록순, 2:지원 적은 순, 3:지원 많은 순, 4:마감 임박 순
				searchGubun : '1',	// 1:프로젝트명, 2:수행사명
				searchKeyword : '',
				projectDivCd : [],
				PRO106 : [],
				PRO107 : [],
				PRO141 : [],
			}, // 검색조건		

			interestAppList : [],
			pageInfo : {},
		};
	},
	bforeCreate() {
		// console.log('beforeCreate');
	},
	created() {
		// console.log('created');
	},
	beforeMount() {
		// console.log('beforeMount');
	},
	mounted() {
		// console.log('mounted');

		if(this.$route.params != undefined && this.$route.params.pageIndex != undefined) {
			this.input = this.$route.params;
		}

		this.getList();
	},
	beforeUpdate() {
		// console.log('beforeUpdate');
	},
	updated() {
		// console.log('update');
	},
	beforeDestroy() {
		// console.log('beforeDestroy');
	},
	destroyed() {
		// console.log('destroyed');
	},

	methods: {
		getList(div, isScroll = true) {
			if(div) this.input.pageIndex = 1;

			this.$.httpPost('/api/prj/tec/getInterestAppList', this.input)
				.then(res => {
					// console.log(res.data);
					this.interestAppList	= res.data.list;
					this.pageInfo			= res.data.pageInfo;
					
					for(var i in this.interestAppList){
						
						if(this.interestAppList[i].dutyFieldCont){
							var tempArr = this.interestAppList[i].dutyFieldCont.split(',');
							this.interestAppList[i].dutyList = [];

							for(var j in tempArr){
								this.interestAppList[i].dutyList.push({dutyFilterCd : tempArr[j].substr(0,2),
																  	   dutyFieldCd  : tempArr[j]});
							}
						}

					}


					if(isScroll) {
						window.scroll(0, 0);
					}
				})
				.catch(err => {
					// console.log('============================='); 
					// console.log(err);
					// console.log(err.response);
					// console.log('============================='); 

					alert(err.response.data.error_description);
				});
		},

		goPage(page) {
			// console.log(page);
			this.input.pageIndex = page;
			this.getList();
		},

		orderSearch(orderGubun) {
			this.input.orderGubun = orderGubun;
			this.input.pageIndex = 1;
			this.getList('', false);
		},
		
		clickProjectInfo(projectSeq) {
			this.$router.push({name : 'PRJ101M02', query : {projectSeq : projectSeq}, params : {caller : { name : 'PRJ104M01', params :this.input}}});
		},		
	},
};
</script>
